import { createContext, useContext, useState } from "react";
import { getAnswer, getRequest } from "../helpers";
import { PromptContext } from "./prompt-store";

export const AnswerContext = createContext(null);

export function useAnswerStore() {
    const [loading, setLoading] = useState(false);
    const [answer, setAnswer] = useState({});
    const [answers, setAnswers] = useState([]);
    const { setPromptLoading } = useContext(PromptContext);

    const fetchData = async (item, currentPromptTitle) => {

        setLoading(true);

        const controller = new AbortController();
        const signal = controller.signal;

        // Cancel the fetch request in 60 seconds
        setTimeout(() => controller.abort(), 60000);

        const request = getRequest(item.model, currentPromptTitle);

        const response = await fetch(
            item.api,
            {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${item.apiKey}`,
                },
                body: JSON.stringify(request),
                signal
            },
        ).then(response => {
            if (!response.ok) {
                const currentAnswer = response.statusText;
                setAnswer({ content: currentAnswer, bot: item.slug, type: 'error' });
                throw new Error(response.statusText);
            } else {
                return response.json();
            }
        }).then((data) => {
            const currentAnswer = getAnswer(item.model, data);
            setAnswer({ content: currentAnswer, bot: item.slug, type: 'message' });
        }).catch((error) => {
            const currentAnswer = error.message;
            setAnswer({ content: currentAnswer, bot: item.slug, type: 'error' });
        }).finally(() => {
            console.log('Data successfully retrieved!');
        })

        // setAnswers([]);
        // setTitle('');
        setLoading(false);
        setPromptLoading(false);
        return response;
    }

    return {
        loading,
        setLoading,
        answer,
        setAnswer,
        answers,
        setAnswers,
        fetchData,
    };
};

export function Answer({ children }) {
    const store = useAnswerStore();

    return (
        <AnswerContext.Provider value={store}>
            {children}
        </AnswerContext.Provider>
    )
}
