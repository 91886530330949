import logo from '../rawbot-logo.png';

const Header = () => {

    return (
        <div className='row justify-content-center'>
            <div className='col-md-6 text-center m-3'>
                <h1 className='text-primary text-lowercase mb-0'>Rawbot</h1>
                <a href='/' className='position-relative' title='Rawbot - Unleash the Power of AI with Rawbot'>
                    <img width={128} src={logo} alt='Rawbot - Unleash the Power of AI with Rawbot' className='img-logo m-3' />
                    <span className="position-absolute badge-beta px-2 bg-primary text-light rounded-pill">beta</span>
                </a>
                <h2 className='h4 my-2 text-primary fw-bold'>Unleash the Power of AI</h2>
                <p>Effortlessly compare AI models and unlock their full potential in your projects. Choose the best AI models based on accurate side-by-side comparisons.</p>
                <p>Works with <strong className='fw-bold'>ChatGPT</strong>, <strong className='fw-bold'>Cohere</strong> and <strong className='fw-bold'>J2 Complete</strong>.</p>
            </div>
        </div>
    )
}

export default Header;
