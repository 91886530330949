export default function FeaturedAiModels({ img }) {

    return (
        <div className='row g-4'>
            <div className='col-lg-4 d-flex align-items-center justify-content-center'>
                <div className='rounded rounded-3 overflow-hidden img-placeholder d-flex justify-content-center align-items-center'>
                    <img src={img} className='object-fit-cover' alt='Network with large points' />
                </div>
            </div>
            <div className='col-lg-8 d-flex flex-column'>
                <div className='flex-grow-1'>
                    <p className='lead'>Rawbot is the premier platform for exploring and comparing the capabilities of artificial intelligence models. Whether you're a researcher, developer, or business leader, Rawbot is your destination for making informed decisions about the AI models that best fit your needs.</p>
                    <p className='lead'>Here is a selection of popular AI models available for comparison.</p>
                </div>
                <ol className='list-unstyled mb-0'>
                    <li className='list-unstyled-item'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h3 className='h5 text-primary'>GPT-3.5 Turbo</h3>
                            </div>
                            <div className='col-auto d-flex'>
                                <a className='btn btn-link justify-self-end' title='More Informaton' href='https://platform.openai.com/'>
                                    <i className="bi bi-box-arrow-up-right"></i>
                                </a>
                            </div>
                        </div>
                        <p>Developed by OpenAI, GPT-3.5 Turbo is an advanced generative model excelling in natural language understanding and generation tasks. Its applications include composing emails or other pieces of text, answering questions based on a set of documents, creating conversational agents, and offering tutoring in various subjects.</p>
                    </li>
                    <li className='list-unstyled-item'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h3 className='h5 text-primary'>Cohere Base</h3>
                            </div>
                            <div className='col-auto d-flex'>
                                <a className='btn btn-link justify-self-end' title='More Informaton' href='https://www.cohere.ai/'>
                                    <i className="bi bi-box-arrow-up-right"></i>
                                </a>
                            </div>
                        </div>
                        <p>Cohere is an AI language model that aims to power human-like understanding and generation of text. It’s designed to be useful across a broad range of applications, such as sentiment analysis, summarization, translation, and content generation. Cohere allows developers to build smarter and more natural applications with a focus on ease of use and seamless integration.</p>
                    </li>
                    <li className='list-unstyled-item'>
                        <div className='row align-items-center'>
                            <div className='col'>
                                <h3 className='h5 text-primary'>Jurassic 2 Grande Instruct</h3>
                            </div>
                            <div className='col-auto d-flex'>
                                <a className='btn btn-link justify-self-end' title='More Informaton' href='https://www.j21.ai/'>
                                    <i className="bi bi-box-arrow-up-right"></i>
                                </a>
                            </div>
                        </div>
                        <p className='mb-0'>J21 is an AI model dedicated to advanced natural language processing, understanding, and generation tasks. Its versatile capabilities make it suitable for various applications, including chatbots, intelligent search, classification, and summarization tasks. J21 aims to offer high-quality performance in a range of industries and projects, from customer support to data analysis.</p>
                    </li>
                </ol>
            </div>
        </div>
    );
};
