const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
const COHERE_API_KEY = process.env.REACT_APP_COHERE_API_KEY;
const J2_API_KEY = process.env.REACT_APP_J2_API_KEY;

export const availableBots = [
    {
        name: "ChatGPT",
        slug: "chat-gpt-3.5-turbo",
        company: "OpenAI",
        api: 'https://api.openai.com/v1/chat/completions',
        model: "gpt-3.5-turbo",
        apiKey: OPENAI_API_KEY,
        checked: true
    },
    {
        name: "Cohere",
        slug: "cohere-base",
        company: "Cohere",
        api: 'https://api.cohere.ai/v1/generate',
        model: "cohere-base",
        apiKey: COHERE_API_KEY,
        checked: true
    },
    {
        name: "J2 Complete",
        slug: "j2-complete",
        company: "AI21 Studio",
        api: 'https://api.ai21.com/studio/v1/j2-grande-instruct/complete',
        model: 'jurassic-2',
        apiKey: J2_API_KEY,
        checked: true
    },
];
