export const getRequest = (model, prompt) => {
    switch (model) {
        case "gpt-3.5-turbo":
            return {
                model: model,
                messages: [{ role: "user", content: prompt }],
                max_tokens: 100,
            };
        // case "chat-gpt-3.5-turbo-0301":
        //     return {
        //         model: model,
        //         messages: [{ role: "user", content: prompt }]
        //     };
        // case "text-davinci-003":
        //     return {
        //         model: model,
        //         prompt: prompt,
        //         max_tokens: 50,
        //     }
        case "jurassic-2":
            return {
                model: model,
                prompt: prompt,
                temperature: 0.5,
                numResults: 1,
                maxTokens: 100,
                stopSequences: [],
            }
        case "cohere-base":
            return {
                // min_tokens: 100,
                prompt: prompt,
                temperature: 0.5,
                max_tokens: 100,
                num_generations: 1,
                stop_sequences: [],
                return_likelihoods: 'NONE'
            }
        default:
            return null;
    }
}

export const getAnswer = (model, response) => {
    switch (model) {
        case "gpt-3.5-turbo":
            return response.choices[0].message.content;
        // case "chat-gpt-3.5-turbo-0301":
        //     return response.choices[0].message.content;
        // case "text-davinci-003":
        //     return response.choices[0].text;
        case "jurassic-2":
            return response.completions[0].data.text;
        case "cohere-base":
            return response.generations[0].text;
        default:
            return null;
    }
}
